<template>
  <div class="user-mt">
    <div class="d-fx search-bk">
      <el-button type="primary" @click="toTurntableForm">添加用户</el-button>
      <el-input class="search-input" v-model="searchParam.name" placeholder="请输入关键词" />
    </div>
    <el-table :data="data.data || []" border v-loading="stat === 'loading'" style="width: 100%">
      <el-table-column prop="id" label="ID" width="100" />
      <el-table-column prop="name" label="用户名" />
      <el-table-column prop="name" label="姓名" />
      <el-table-column prop="name" label="电话" />
      <el-table-column prop="createTime" label="创建时间" #default="{ createTime }">
        <span>{{ $filters.dateFilter(createTime) }}</span>
      </el-table-column>
      <el-table-column label="操作">
        <template #default>
          <el-button type="primary">编辑</el-button>
          <el-button>删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-page">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="data.total || 0"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "userManage"
};
</script>
<script setup>
import { reactive, watch } from "vue";
import { debounce } from "@/utils";
import { useAsync } from "@/utils/useAsync";
import { useRouter } from "vue-router";
import { getUserList } from "@/api/sys";

const router = useRouter();

const searchParam = reactive({
  page: 1,
  paginate: 10,
  isUpdate: false,
  name: ""
});

const handleCurrentChange = (val) => {
  searchParam.page = val;
};

const { data, run, stat } = useAsync();

watch(
  [searchParam],
  debounce(() => {
    run(getUserList({ ...searchParam }));
  }, 500),
  {
    immediate: true
  }
);

const toTurntableForm = () => {
  router.replace("/activity/turntableForm");
};
</script>

<style lang="scss" scoped></style>
