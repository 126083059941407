/*
author: chenpeisehng
copyright: chenpeisehng
create-data: 2022-03-10
*/
import { reactive, ref, toRefs } from "vue";

const defaultInitialState = {
  data: {
    data: [],
    total: 0,
    totalPage: 0
  },
  error: null
};

export const useAsync = (initialState) => {
  const isLoading = ref(false);
  const state = reactive({ ...defaultInitialState, ...initialState });

  const setData = (data) => {
    isLoading.value = false;
    state.data = data;
    state.error = null;
  };

  const setError = (error) => {
    isLoading.value = false;
    state.error = error;
  };

  const run = (promise) => {
    if (!promise || !promise.then) {
      throw new Error("您传入的不是promise类型");
    }
    isLoading.value = true;
    return promise
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        setError(error);
        return error;
      });
  };

  return {
    isLoading,
    ...toRefs(state),
    run,
    setData,
    setError
  };
};
